import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { useAnalytics } from '../../../hooks/useAnalytics/useAnalytics';
import { teamStructureUploaded, errorMessageViewed } from '../../../Analytics/index';
import { updateFile } from '../../../stores/file/FileEvents';
import { isCSV } from '../../../config/utils/functions';
import CustomToast from '../../CustomToast/CustomToast';
import InputFileWrapperStyles from './InputFileWrapper.styles';

interface InputFileWrapperProps {
  children: JSX.Element[] | JSX.Element;
  setDragEnter: React.Dispatch<React.SetStateAction<boolean>>;
  dragEnter: boolean;
  screenSection?: string;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
  showToastError: boolean;
}

function InputFileWrapper({
  children,
  setDragEnter,
  dragEnter,
  screenSection,
  setHasError,
  showToastError,
}: InputFileWrapperProps): JSX.Element {
  const classes = InputFileWrapperStyles();
  const { dispatchGenericEvent } = useAnalytics();
  const { formatMessage } = useIntl();
  const wrapperRef = useRef(null);

  /* istanbul ignore next */
  const onDragEnter = () => setDragEnter(true);

  /* istanbul ignore next */
  const onDragLeave = () => setDragEnter(false);

  /* istanbul ignore next */
  const addClassNameByDragEnterState = () => {
    if (dragEnter) {
      return `${classes.container} ${classes.containerBorderDrag}`;
    }
    return `${classes.container} ${classes.containerBorderDefault}`;
  };

  const onFileDrop = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    const url = window.URL.createObjectURL(file);
    setHasError(false);
    setDragEnter(false);

    if (!isCSV(file.name)) {
      setHasError(true);
      if (showToastError) {
        CustomToast({
          type: 'error',
          message: formatMessage({ id: 'CustomToast.PROCESSING_FILE_ERROR' }),
        });
      }
      dispatchGenericEvent(errorMessageViewed, {
        screen_name: 'Team Structure',
        error_type: 'team structure upload error',
        error_message: 'Processing failed. The file type is invalid or the template incomplete.',
        screen_section: screenSection || 'Team Structure Upload',
        time_of_day: new Date().getHours(),
      });
      return;
    }
    dispatchGenericEvent(teamStructureUploaded, {
      screen_section: screenSection || 'No structure yet page',
    });
    updateFile({
      fileName: file.name,
      fileUrl: url,
      fileSize: file.size,
      peopleFound: 0,
    });
  };

  const inputProps = {
    type: 'file',
    value: '',
    accept: '.csv',
    className: classes.inputFile,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => onFileDrop(e),
    'data-testid': 'input',
  };

  return (
    <div className={addClassNameByDragEnterState()}>
      <div ref={wrapperRef} onDragEnter={onDragEnter} onDragLeave={onDragLeave}>
        <input {...inputProps} />
        {children}
      </div>
    </div>
  );
}

export default InputFileWrapper;
