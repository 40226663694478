import React from 'react';
import IllustrationFileImg from './Illustration_File@2x.png';

interface ImgProps {
  className?: string;
}

function CsvPaper({ className = '' }: ImgProps): JSX.Element {
  return <img className={className} src={IllustrationFileImg} alt="A papersheet with lines" />;
}

export default CsvPaper;
