import React, { useEffect, useState } from 'react';
import { Button } from '@admin-portal-shared-components/button';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { useStore } from 'effector-react';
import { CSVLink } from 'react-csv';
import { Dialog } from '@hexa-ui/components';
import { removeDoubleQuotes } from '../../../../../config/utils/functions';
import { teamStructureUxButtonClicked } from '../../../../../Analytics/index';
import { useAnalytics } from '../../../../../hooks/useAnalytics/useAnalytics';
import { getTeamStructureBackupFileEffect } from '../../../../../stores/teamStructure/TeamStructureEffects';
import TeamStructureStore from '../../../../../stores/teamStructure/TeamStructureStore';
import FileStore from '../../../../../stores/file/FileStore';
import TemplateDownload from '../../../../../components/TemplateDownload/TemplateDownload';
import DragAndDropInputFile from '../../../../../components/DragAndDropInputFile/DragAndDropInputFile';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import ImportDialogStyles from './ImportDialog.styles';

function ImportDialog(): JSX.Element {
  const classes = ImportDialogStyles();
  const { formatMessage } = useIntl();
  const { dispatchGenericEvent } = useAnalytics();

  const { isProcessed } = useStore(FileStore);
  const { teamStructure } = useStore(TeamStructureStore);

  const [showModal, setShowModal] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [CSVFile, setCSVFile] = useState('');

  useEffect(() => {
    /* istanbul ignore next */
    if (isProcessed && showDialog) {
      closeDialog();
      openModal();
    }
  }, [isProcessed, showDialog]);

  /* istanbul ignore next */
  useEffect(() => {
    if (showDialog) {
      getTeamStructureBackupFileEffect(teamStructure?.id).then((backupFile) => {
        setCSVFile(removeDoubleQuotes(backupFile));
      });
    }
  }, [showDialog, teamStructure?.id]);

  /* istanbul ignore next */
  const openModal = () => setShowModal(true);

  /* istanbul ignore next */
  const closeModal = () => setShowModal(false);

  /* istanbul ignore next */
  const closeDialog = () => setShowDialog(false);

  const handleDialogButton = () => {
    dispatchGenericEvent(teamStructureUxButtonClicked, {
      button_name: 'Import',
      button_label: formatMessage({ id: 'ImportDialog.IMPORT_BTN_TEXT' }),
      screen_section: 'Team Structure Header',
      hierarchy: null,
    });
    setShowDialog(true);
  };

  const handleAnalyticsDownloadBackupButton = () => {
    dispatchGenericEvent(teamStructureUxButtonClicked, {
      button_name: 'download-backup-file',
      button_label: formatMessage({ id: 'ImportDialog.DOWNLOAD_BACKUP' }),
      screen_section: 'Import structure',
      hierarchy: null,
    });
  };

  return (
    <>
      <Button
        data-testid="DialogButton"
        onClick={handleDialogButton}
        size="large"
        variant="secondary"
        className={classes.importButton}
      >
        {formatMessage({ id: 'ImportDialog.IMPORT_BTN_TEXT' })}
      </Button>
      <ConfirmationModal data-testid="ConfirmationModal" open={showModal} closeModal={closeModal} />
      <Dialog.Root onClose={closeDialog} open={showDialog} onPointerDownOutside={closeDialog}>
        <Typography className={classes.title} variant="h1">
          {formatMessage({ id: 'ImportDialog.IMPORT_STRUCTURE' })}
        </Typography>
        <div className={classes.container}>
          <Typography className={classes.subtitle} variant="subtitle1">
            {formatMessage({ id: 'ImportDialog.WARNING_OVERRIDE' })}
          </Typography>
          <Typography className={classes.currentStructure} variant="h2">
            {formatMessage({ id: 'ImportDialog.CURRENT_STRUCTURE' })}
          </Typography>
          <Typography className={classes.saveBackup} variant="subtitle2">
            {formatMessage({ id: 'ImportDialog.SAVE_BACKUP' })}
          </Typography>
          <Typography />
          <CSVLink
            data={CSVFile}
            className={classes.downloadBackup}
            target="_blank"
            filename="TeamStructureTemplate.csv"
            uFEFF={false}
            onClick={handleAnalyticsDownloadBackupButton}
            data-testid="DownloadBackupButton"
          >
            {formatMessage({ id: 'ImportDialog.DOWNLOAD_BACKUP' })}
          </CSVLink>
          <Typography className={classes.uploadFile} variant="h2">
            {formatMessage({ id: 'ImportDialog.UPLOAD_FROM_FILE' })}
          </Typography>
          <div className={classes.dragDropWrapper}>
            <DragAndDropInputFile screenSection="Import structure" />
          </div>
          <TemplateDownload lightText screenSection="Import structure" />
        </div>
      </Dialog.Root>
    </>
  );
}

export default ImportDialog;
