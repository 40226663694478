import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { IconButton } from '@admin-portal-shared-components/icon-button';
import { ProgressMeter } from '@admin-portal-shared-components/progress-meter';
import { X } from '@admin-portal-shared-components/icons';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import CsvPaper from '../../assets/CsvPaper';
import { setIsProcessed, resetFileStore } from '../../stores/file/FileEvents';
import FileStore from '../../stores/file/FileStore';
import { conditionalClass, formatBytes } from '../../config/utils/functions';
import ImportedFileStyles from './ImportedFile.styles';

interface ImportedFileProps {
  hasProgress?: boolean;
  taller?: boolean;
}

/* istanbul ignore next */
function ImportedFile({ hasProgress = false, taller = false }: ImportedFileProps): JSX.Element {
  const classes = ImportedFileStyles();
  const { formatMessage } = useIntl();
  const { file } = useStore(FileStore);
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (file.fileName && hasProgress) {
      const interval = setInterval(() => {
        setProgress((oldValue: number) => {
          const newValue = oldValue + 1;
          if (newValue === 150) {
            setIsProcessed(true);
            clearInterval(interval);
          }
          return newValue;
        });
      }, 15);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPositionFoundText = (peopleFound: number): string =>
    peopleFound === 1
      ? formatMessage({ id: 'ImportedFile.POSITION_FOUND_TEXT' })
      : formatMessage({ id: 'ImportedFile.POSITION_FOUND_TEXT_PLURAL' });

  const handleOnClick = () => {
    resetFileStore(null);
  };

  return (
    <div
      className={`${classes.container} ${conditionalClass(taller, classes.taller)}`}
      data-testid="importedFile-container"
    >
      <IconButton
        icon={X}
        size="small"
        variant="tertiary"
        className={classes.cancelButton}
        onClick={handleOnClick}
      />
      <CsvPaper className={classes.csvPaper} />
      <div data-testid="content" className={classes.content}>
        <Typography className={classes.fileName} variant="subtitle2" title={file.fileName}>
          {file.fileName}
        </Typography>
        <Typography className={classes.fileInfo} variant="subtitle2">
          {formatBytes(file.fileSize)}
        </Typography>
        {hasProgress ? (
          <div className={classes.progressMeterWrapper}>
            <ProgressMeter
              data-testid="progressMeter"
              label={formatMessage({ id: 'ImportedFile.PROCESSING_FILE' })}
              value={progress}
              maxValue={100}
            />
          </div>
        ) : (
          <Typography className={classes.fileInfo} variant="subtitle2" style={{ display: 'none' }}>
            {file.peopleFound} {getPositionFoundText(file.peopleFound)}
          </Typography>
        )}
      </div>
    </div>
  );
}

export default ImportedFile;
