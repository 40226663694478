import React from 'react';

function CSVIcon(): JSX.Element {
  return (
    <svg
      width="64px"
      height="64px"
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>5B294A42-36AF-4347-BF16-9FA63F70E9F2@3x</title>
      <g id="...-Team-structure" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Team-structure---Empty"
          transform="translate(-732.000000, -512.000000)"
          fillRule="nonzero"
        >
          <g id="Main-content---Beta-1" transform="translate(112.000000, 152.000000)">
            <g id="Upload-area" transform="translate(440.000000, 328.000000)">
              <g id="Group-12" transform="translate(80.000000, 32.000000)">
                <g id="Illustration_File" transform="translate(100.000000, 0.000000)">
                  <g id="paper" transform="translate(6.400000, 0.000000)">
                    <g id="Group">
                      <polygon
                        id="Path"
                        fill="#B5CEFF"
                        points="46.5346941 64 0 64 0 4.38025 46.5346941 4.38025"
                      />
                      <g transform="translate(5.465306, 0.000000)" id="Path">
                        <path
                          d="M46.5346941,59.61975 L31.0654633,54.854 L8.25597029,52.8605 C3.48181203,52.0175 0,47.8515 0,42.982 L0,0 L46.5346941,0 L46.5346941,59.61975 Z"
                          fill="#E9F2FF"
                        />
                        <polygon
                          fill="#DEEBFF"
                          points="40.1086432 7.10542736e-15 40.1086432 58.485 46.5346941 59.61975 46.5346941 0"
                        />
                        <path
                          d="M8.50074134,52.869625 L46.5345694,59.61975 L16.9641807,41.565875 C16.9641807,41.565875 17.3582845,52.869625 8.50074134,52.869625 L8.50074134,52.869625 Z"
                          fill="#91A8DD"
                        />
                      </g>
                    </g>
                    <g id="Group" transform="translate(12.800000, 8.800000)" fill="#91A8DD">
                      <path
                        d="M0.959228323,1.95140432 L30.2407717,1.95140432 C30.7705448,1.95140432 31.2,1.51457365 31.2,0.975702162 C31.2,0.436830673 30.7705448,0 30.2407717,0 L0.959228323,0 C0.429455186,0 0,0.436830673 0,0.975702162 C0,1.51457365 0.429455186,1.95140432 0.959228323,1.95140432 Z"
                        id="Path"
                      />
                      <path
                        d="M30.2407717,6.01624065 L0.959228323,6.01624065 C0.429455186,6.01624065 0,6.45307133 0,6.99194282 C0,7.53081431 0.429455186,7.96764498 0.959228323,7.96764498 L30.2407717,7.96764498 C30.7705448,7.96764498 31.2,7.53081431 31.2,6.99194282 C31.2,6.45307133 30.770669,6.01624065 30.2407717,6.01624065 L30.2407717,6.01624065 Z"
                        id="Path"
                      />
                      <path
                        d="M30.2407717,12.032355 L0.959228323,12.032355 C0.429455186,12.032355 0,12.4691857 0,13.0080572 C0,13.5469287 0.429455186,13.9837593 0.959228323,13.9837593 L30.2407717,13.9837593 C30.7705448,13.9837593 31.2,13.5469287 31.2,13.0080572 C31.2,12.4691857 30.770669,12.032355 30.2407717,12.032355 Z"
                        id="Path"
                      />
                      <path
                        d="M30.2407717,18.0485957 L0.959228323,18.0485957 C0.429455186,18.0485957 0,18.4854263 0,19.0242978 C0,19.5631693 0.429455186,20 0.959228323,20 L30.2407717,20 C30.7705448,20 31.2,19.5631693 31.2,19.0242978 C31.2,18.4854263 30.770669,18.0485957 30.2407717,18.0485957 Z"
                        id="Path"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CSVIcon;
