import React from 'react';
import { Button, Modal } from '@hexa-ui/components';
import { Typography } from '@material-ui/core';
import { useStore } from 'effector-react';
import { useIntl } from 'react-intl';
import { teamStructureImported } from '../../../../../../Analytics/index';
import CustomToast from '../../../../../../components/CustomToast/CustomToast';
import { getFileFromUrl } from '../../../../../../config/utils/functions';
import { useAnalytics } from '../../../../../../hooks/useAnalytics/useAnalytics';
import { resetFileStore, setIsProcessed } from '../../../../../../stores/file/FileEvents';
import FileStore from '../../../../../../stores/file/FileStore';
import { updateTeamStructureEffect } from '../../../../../../stores/teamStructure/TeamStructureEffects';
import ConfirmationModalStyles from './ConfirmationModal.styles';

interface ConfirmationModalProps {
  open: boolean;
  closeModal: () => void;
}
/* istanbul ignore next */
function ConfirmationModal({ open, closeModal }: ConfirmationModalProps): JSX.Element {
  const classes = ConfirmationModalStyles();
  const { formatMessage } = useIntl();
  const { dispatchGenericEvent } = useAnalytics();
  const { file } = useStore(FileStore);

  const handleCancel = () => {
    closeModal();
    resetFileStore(null);
  };

  const handleOverride = async () => {
    const { fileUrl, fileName } = file;
    const csvfile = await getFileFromUrl(fileUrl, fileName);

    updateTeamStructureEffect(csvfile);

    const unwatchUpdateTeamStructureEffectDone = updateTeamStructureEffect.done.watch(() => {
      dispatchGenericEvent(teamStructureImported, {
        screen_section: 'Override structure',
      });

      window.URL.revokeObjectURL(fileUrl);
      closeModal();
      resetFileStore(null);
      setIsProcessed(false);

      CustomToast({
        type: 'success',
        message: formatMessage({ id: 'CustomToast.SUCCESS_UPDATED' }),
      });

      /* istanbul ignore next */
      if (typeof unwatchUpdateTeamStructureEffectDone === 'function') {
        unwatchUpdateTeamStructureEffectDone();
      }
    });

    const unwatchUpdateTeamStructureEffectFail = updateTeamStructureEffect.fail.watch(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (result: any) => {
        const errorCsv = result.error?.response?.data;

        if (errorCsv) {
          CustomToast({
            type: 'error',
            message: formatMessage({ id: 'CustomToast.PROCESSING_FILE_ERROR' }),
          });
        }

        CustomToast({
          type: 'error',
          message: formatMessage({ id: 'CustomToast.CONNECTION_ERROR' }),
        });

        /* istanbul ignore next */
        if (typeof unwatchUpdateTeamStructureEffectFail === 'function') {
          unwatchUpdateTeamStructureEffectFail();
        }

        closeModal();
        resetFileStore(null);
        setIsProcessed(false);
      },
    );
  };

  return (
    <Modal.Root
      open={open}
      actions={
        <div className={classes.actionsContainer}>
          <Modal.Cancel>
            <Button onClick={handleCancel} size="large" variant="secondary">
              {formatMessage({ id: 'ImportDialog.ConfirmationModal.CANCEL_BTN_TEXT' })}
            </Button>
          </Modal.Cancel>
          <Modal.Action>
            <Button onClick={handleOverride} size="large" variant="primary">
              {formatMessage({ id: 'ImportDialog.ConfirmationModal.CONFIRM_OVERRIDE' })}
            </Button>
          </Modal.Action>
        </div>
      }
    >
      <div className={classes.container}>
        <Typography className={classes.title} variant="h1">
          {formatMessage({ id: 'ImportDialog.ConfirmationModal.MODAL_OVERRIDE' })}
        </Typography>
        <Typography className={classes.text} variant="subtitle1">
          {formatMessage({ id: 'ImportDialog.ConfirmationModal.MODAL_WARNING_A' })}
        </Typography>
        <Typography className={classes.text} variant="subtitle1">
          {formatMessage({ id: 'ImportDialog.ConfirmationModal.MODAL_WARNING_B' })}
        </Typography>
      </div>
    </Modal.Root>
  );
}

export default ConfirmationModal;
