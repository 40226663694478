import React from 'react';
import { useStore } from 'effector-react';
import useIsManager from '../../../../hooks/roles/useIsManager/useIsManager';
import TeamStructureStore from '../../../../stores/teamStructure/TeamStructureStore';
import ImportDialog from './ImportDialog/ImportDialog';
import TeamStructureTitle from './TeamStructureTitle/TeamStructureTitle';
import TeamStructureHeaderStyles from './TeamStructureHeader.styles';

function TeamStructureHeader(): JSX.Element {
  const classes = TeamStructureHeaderStyles();
  const { done, teamStructure } = useStore(TeamStructureStore);

  const hasStructure = done && teamStructure?.structure?.length > 0;
  const isManager = useIsManager();

  return (
    <div className={classes.container}>
      <TeamStructureTitle isBetaVersion />
      <div className={classes.buttonsContainer}>
        {isManager && hasStructure && <ImportDialog data-testid="ImportDialog" />}
      </div>
    </div>
  );
}

export default TeamStructureHeader;
