import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Popover, ListItem, List, ListItemText } from '@material-ui/core';
import { IconButton } from '@admin-portal-shared-components/icon-button';
import { MoreHorizontal } from '@admin-portal-shared-components/icons';
import EllipsisPopoverStyles from './EllipsisPopover.styles';
import { conditionalClass, setStorageAgentEmail } from '../../config/utils/functions';
import { ADMIN_PORTAL_AGENT_ROUTE } from '../../config/constants';
import { useAnalytics } from '../../hooks/useAnalytics/useAnalytics';
import { teamStructureUxButtonClicked } from '../../Analytics/index';

interface EllipsisPopoverProps {
  email: string;
  hierarchy: string;
}

export function EllipsisPopover({ email, hierarchy }: EllipsisPopoverProps): JSX.Element {
  const classes = EllipsisPopoverStyles();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { dispatchGenericEvent } = useAnalytics();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClose = () => setAnchorEl(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const IconButtonProps: any = {
    icon: MoreHorizontal,
    size: 'small',
    variant: 'tertiary',
    className: `${classes.moreHorizontalButton} ${conditionalClass(
      !!anchorEl,
      classes.moreHorizontalButtonActive,
    )}`,
    onClick: handleClick as never,
    'data-testid': 'moreHorizontalButton',
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const PopoverProps: any = {
    id,
    open,
    anchorEl,
    onClose: handleClose,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  };

  const handleSpecialization = () => {
    dispatchGenericEvent(teamStructureUxButtonClicked, {
      button_name: 'set-specialization',
      button_label: 'Set specialization',
      screen_section: 'Single Member Card',
      hierarchy,
    });
    setStorageAgentEmail(email);
    history.push(ADMIN_PORTAL_AGENT_ROUTE);
  };

  return (
    <>
      <IconButton {...IconButtonProps} />
      <Popover {...PopoverProps}>
        <List disablePadding>
          <ListItem className={classes.listItem} onClick={handleSpecialization} button>
            <ListItemText
              data-testid="set-specialization-item"
              primary={formatMessage({ id: 'EllipsisPopover.SET_SPECIALIZATION' })}
            />
          </ListItem>
        </List>
      </Popover>
    </>
  );
}

export default EllipsisPopover;
